/* Estilos Globais */
/* Estilos Globais */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  margin: 10;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', Arial, sans-serif;
  background: linear-gradient(135deg, #f5f5f5, #d1a15c); 
  display: flex-box; /* Correção: troquei "flex-box" por "flex" */
  flex-direction: column;
  justify-content: center; /* Centraliza verticalmente */
  align-items: center; /* Centraliza horizontalmente */
  min-height: 100vh; /* Ocupa toda a altura da tela */
  color: #4e342e;
  padding: 200; /* Removi o padding para evitar desalinhamento */
  overflow-x: hidden;
}

/* Container Principal */
.container {
  /* width: 100%; */
  max-width: 900;
  padding: -20px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  margin: 20px; /* Adicionei margem externa */
}

/* Menu Toggle (Mobile) */
.menu-toggle {
  display: none;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  margin: 20px;
}

.menu-toggle .bar {
  width: 30px;
  height: 4px;
  background-color: #7b5c3c;
  border-radius: 2px;
}

/* Nav Menu */
.nav-menu {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.nav-menu ul {
  list-style: none;
  display: flex;
  gap: 150px;
}

.nav-menu a {
  text-decoration: none;
  color: #7b5c3c;
  font-size: 1.3rem;
  font-weight: 600;
  transition: color 0.3s ease;
}

.nav-menu a:hover {
  color: #9e3d27;
}

/* Seção Sobre */
.sobre {
  margin-bottom: 40px;
}

.sobre .foto {
  width: 150px;
  height: 150px;
  max-width: 100%;
  border-radius: 50%;
  margin-top: -25px;
  margin-bottom: 10px;  
  border: 5px solid #f5f5f5;
}

.sobre h1 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #9e3d27;
  letter-spacing: 1px;
}

.sobre .descricao {
  font-size: 1.2rem;
  color: #5f4b41;
  max-width: 900px;
  margin: 0 auto;
  line-height: 1.6;
  text-align: justify;
}

/* Links Sociais */
.redes h2 {
  font-size: 1rem;
  color: #9e3d27;
  margin-bottom: 20px;
}

.links .link {
  display: inline-block;
  background-color: #7b5c3c;
  color: white;
  padding: 12px;
  margin: 10px 0;
  width: 60%;
  border-radius: 30px;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 300;
  text-transform: uppercase;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.links .link:hover {
  background-color: #9e3d27;
  transform: translateY(-5px);
}

/* Rodapé */
footer {
  margin-top: 30px;
  font-size: 0.9rem;
  color: #5f4b41;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
}

footer a {
  color: #7b5c3c;
  text-decoration: none;
}

footer a:hover {
  color: #9e3d27;
}

/* Responsividade */

@media (max-width: 1024px) {
  .nav-menu {
    gap: 15px;
  }

  .nav-menu a {
    font-size: 0.9rem;
  }

  .sobre .foto {
    width: 130px;
    height: 130px;
  }

  .sobre h1 {
    font-size: 1.8rem;
  }

  .sobre .descricao {
    font-size: 0.9rem;
    margin: 0 20px;
  }

  .links .link {
    width: 80%;
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .nav-menu {
    display: none;
    flex-direction: column;
    align-items: center;
  }

  .nav-menu.active {
    display: flex;
  }

  .menu-toggle {
    display: flex;
  }

  .sobre .foto {
    width: 120px;
    height: 120px;
  }

  .sobre h1 {
    font-size: 1.6rem;
  }

  .sobre .descricao {
    font-size: 0.9rem;
    margin: 0 15px;
  }

  .links .link {
    width: 90%;
    padding: 10px;
    font-size: 0.9rem;
  }

  footer {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .sobre .foto {
    width: 180px;
    height: 180px;
  }

  .sobre h1 {
    font-size: 1.4rem;
  }

  .sobre .descricao {
    font-size: 0.8rem;
    margin: 0 10px;
  }

  .links .link {
    width: 70%;
    padding: 8px;
    font-size: 0.8rem;
  }

  footer {
    font-size: 0.7rem;
  }
}

/* Seção Agenda */
.agenda {
  margin-top: 0px;
  padding: 0px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin-bottom: 100px;
  width: 100%;  
  font-size: 1.2rem;


}

.agenda-item {  
  padding: 5px;
  text-align: center;
}

.agenda h3 {
  margin-bottom: 5px;
  font-size: 1.2rem;
  color: #5f4b41;;
}

/* Ajuste na navegação do carrossel (dots) */
/* Estilo para os dots (pontos de navegação do Slick) */
.slick-dots {
  margin-top: 40px; /* Espaçamento superior */
  display: flex;
  justify-content: center; /* Centraliza os pontos horizontalmente */
  list-style: none; /* Remove a lista de marcadores padrão */
  padding: 0; /* Remove o padding */
}

/* Estilo para cada ponto individual */
.slick-dots li {
  margin: 0 10px; /* Espaçamento entre os pontos */
  position: relative;
}

/* Estilo para os pontos inativos */
.slick-dots li button {
  /* background-color: rgba(0, 0, 0, 0.3); Cor do ponto inativo */
  border: none;
  width: 12px; /* Tamanho do ponto */
  height: 12px; /* Tamanho do ponto */
  border-radius: 50%; /* Faz os pontos circulares */
  cursor: pointer;
  position: relative;
  z-index: 1; /* Garante que o ponto esteja no topo */
  transition: background-color 0.3s ease-out; /* Transição suave para cor */
}

/* Efeito ao passar o mouse por cima do ponto */
.slick-dots li button:hover {
  background-color: #9e3d27; /* Cor do ponto ao passar o mouse */
}

/* Estilo para o ponto ativo (representa o slide atual) */
.slick-dots li.slick-active button {
  background-color: transparent; /* Remove a cor de fundo do botão ativo */
}

/* Pseudoelemento ::before para o ponto ativo */
.slick-dots li.slick-active button::before {
  content: ''; /* Cria um pseudoelemento */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #9e3d27; /* Cor do ponto ativo */
  border-radius: 50%;
  transform: scale(1.4); /* Aumenta o ponto ativo para destacá-lo */
  box-shadow: 0 0 6px rgba(158, 61, 39, 0.8); /* Adiciona uma sombra no ponto ativo */
  z-index: -1; /* Coloca o pseudoelemento atrás do botão */
}

/* Efeito de transição mais suave para o foco */
.slick-dots li button:focus {
  outline: none; /* Remove o contorno padrão */
  box-shadow: 0 0 6px rgba(158, 61, 39, 0.8); /* Adiciona a sombra no foco */
}



/* Estilo para o ponto de navegação em dispositivos móveis */
@media (max-width: 768px) {
  .slick-dots {
    margin-top: 20px; /* Menor margem superior em telas menores */
  }

  .slick-dots li button {
    width: 10px; /* Tamanho reduzido do ponto */
    height: 10px; /* Tamanho reduzido do ponto */
  }

  .slick-dots li.slick-active button {
    width: 12px; /* Tamanho ligeiramente maior para o ponto ativo */
    height: 12px; /* Tamanho ligeiramente maior para o ponto ativo */
  }
}


/* Para evitar que os dots fiquem colados no botão do WhatsApp */
#contato {
  margin-top: 10px;
}

/* Estilos para o botão WhatsApp */
.whatsapp-link {
  display: inline-flex;
  align-items: center;
  background-color: #25D366;
  color: white;
  padding: 12px 15px;
  border-radius: 30px;
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 300;
  text-transform: uppercase;
  transition: background-color 0.3s ease, transform 0.3s ease;
  width: 60%;
  justify-content: center;
  gap: 10px;
}

.whatsapp-link:hover {
  background-color: #128C7E;
  transform: translateY(-5px);
}

.whatsapp-icon {
  font-size: 1.2rem;
  margin-right: 10px;
}

/* Ajuste para dispositivos móveis */
@media (max-width: 768px) {
  .whatsapp-link {
    width: 70%;
    padding: 10px;
    font-size: 0.9rem;
  }
}
.disclaimer-text {
  font-size: 0.9rem;
  color: #5f4b41;
  text-align: center;
  margin-top: 20px;
  padding: 0 20px;
}
/* Estilos para os botões de compartilhamento */
.social-share-buttons {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-share-buttons h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-weight: bold;
  color: #333;
}

.social-share-buttons button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px 0;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  width: 250px; /* Tamanho fixo para os botões */
}

.social-share-buttons button:hover {
  background-color: #45a049;
}

.social-share-buttons button:active {
  background-color: #388e3c;
}
