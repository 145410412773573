/* Reset básico de margin e padding */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Fundo suave e acolhedor */
  body {
    background-color: #f3f4f6; /* cor de fundo suave */
    font-family: 'Poppins', sans-serif; /* Fonte moderna e profissional */
    color: #333;
  }
  
  /* Estilização da página de cadastro */
  .cadastro-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    /* background-image: url('bg.jpg'); Imagem suave de fundo (ex: tema religioso) */
    background-size: cover;
    background-position: center;
    padding: 300px;
    text-align: center;
  }
  
  h2 {
    font-size: 32px;
    font-weight: bold;
    color: #1c1c1c;
    margin-bottom: 20px;
    text-transform: uppercase; /* Maiúsculas para dar um tom mais solene */
  }
  
  /* Texto de introdução */
  .intro-text {
    font-size: 20px;
    line-height: 1.6;
    color: #6c757d;
    margin-bottom: 30px;
    max-width: 600px;
  }
  
  /* Formulário */
  form {
    width: 100%;
    max-width: 450px;
    background-color: rgba(255, 255, 255, 0.8); /* Fundo branco translúcido */
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Sombras suaves para um efeito moderno */
  }
  
  /* Estilização dos inputs */
  .input-cadastro {
    width: 100%;
    padding: 15px;
    margin: 10px 0;
    border: 1px solid #dcdcdc;
    border-radius: 10px;
    font-size: 18px;
    color: #333;
    background-color: #f8f9fa;
    transition: border 0.3s ease-in-out;
  }
  
  /* Efeito ao focar no input */
  .input-cadastro:focus {
    border-color: #5cb85c;
    outline: none;
  }
  
  /* Botão de cadastro */
  .btn-cadastro {
    width: 100%;
    padding: 15px;
    background-color: #5cb85c; /* Verde mais suave */
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  /* Efeito ao passar o mouse sobre o botão */
  .btn-cadastro:hover {
    background-color: #4cae4c;
    transform: scale(1.05);
  }
  
  /* Mensagens de status */
  .status-message {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 600;
  }
  
  .status-message.success {
    color: #28a745;
  }
  
  .status-message.error {
    color: #dc3545;
  }
  
  /* Responsividade para telas pequenas */
  @media (max-width: 600px) {
    .cadastro-container {
      padding: 20px;
    }
  
    h2 {
      font-size: 26px;
    }
  
    .intro-text {
      font-size: 16px;
    }
  
    .input-cadastro {
      padding: 12px;
      font-size: 16px;
    }
  
    .btn-cadastro {
      padding: 12px;
      font-size: 16px;
    }
  }
  